import auth0 from 'auth0-js';
import { navigate } from 'gatsby';

const isBrowser = typeof window !== 'undefined';

const auth = isBrowser
  ? new auth0.WebAuth({
      domain: process.env.GATSBY_AUTH0_DOMAIN,
      clientID: process.env.GATSBY_AUTH0_CLIENTID,
      redirectUri: process.env.GATSBY_AUTH0_CALLBACK,
      responseType: 'token id_token',
      scope: 'openid profile email',
    })
  : {};

const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false,
};

let user = {};

const APP_VERSION = process.env.GATSBY_APP_VERSION;

export const isAuthenticated = () => {
  if (!isBrowser || localStorage === null) {
    return false;
  }

  const storedVersion = localStorage.getItem('appVersion');
  if (storedVersion !== APP_VERSION) {
    // Version mismatch - clear storage and force re-login
    localStorage.clear();
    return false;
  }

  return localStorage.getItem('isLoggedIn') === 'true';
};

export const login = () => {
  if (!isBrowser) {
    return;
  }

  auth.authorize();
};

const setSession =
  (cb = () => {}, redirect = true) =>
  (err, authResult) => {
    if (err) {
      localStorage.removeItem('tokens');
      localStorage.removeItem('sid');
      localStorage.removeItem('appVersion');
      navigate('/');
      cb();
      return;
    }

    if (authResult && authResult.accessToken && authResult.idToken) {
      const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
      const tokens = {
        accessToken: authResult.accessToken,
        idToken: authResult.idToken,
        expiresAt,
      };
      user = authResult.idTokenPayload;
      const { sid } = authResult.idTokenPayload;
      localStorage.setItem('tokens', JSON.stringify(tokens));
      localStorage.setItem('sid', sid);
      localStorage.setItem('isLoggedIn', true);
      localStorage.setItem('appVersion', APP_VERSION);
      if (redirect) {
        navigate('/muj-ucet/');
      }
      cb();
    }
  };

export const silentAuth = (callback) => {
  if (!isAuthenticated()) return callback();
  auth.checkSession({}, setSession(callback, false));
};

export const handleAuthentication = () => {
  if (!isBrowser) {
    return;
  }

  auth.parseHash(setSession(undefined, true));
};

export const getProfile = () => user;

export const logout = () => {
  localStorage.setItem('isLoggedIn', false);
  auth.logout({ returnTo: window.location.origin });
};
