import React from 'react';
import { Link } from 'gatsby';
import AccountIcon from '../../../assets/icons/account.svg';

export default function AccountButton({ onClick }: { onClick: () => void }) {
  return (
    <div className="buttons">
      <Link
        className="navbar-item button is-primary"
        to="/muj-ucet/"
        onClick={onClick}
      >
        <span className="icon-text">
          <span className="icon">
            <AccountIcon />
          </span>{' '}
          <span>Můj účet</span>
        </span>
      </Link>
    </div>
  );
}
