import * as Sentry from '@sentry/gatsby';

Sentry.init({
  dsn: 'https://55a4c2fc19b7460ba22539085e0247a6@o1078978.ingest.sentry.io/6083513',
  sampleRate: 1.0, // Adjust this value in production
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend(event) {
    // Modify the event here
    if (event.user) {
      // Don't send user's email address
      delete event.user.email;
    }
    return event;
  },
  environment: process.env.NODE_ENV,
});
