import React from 'react';
import PropTypes from 'prop-types';
import '../styles/mystyles.scss';
import Footer from './footer';
import Nav from './nav';
import BottomMenu from './bottom-menu';
import SecondaryNav from './secondary-nav';

function Layout({ children }) {
  return (
    <>
      <SecondaryNav />
      <section className="section pb-0">
        <Nav />
      </section>
      <section className="section">{children}</section>

      <section className="section bottom-menu">
        <BottomMenu />
      </section>
      <section>
        <Footer />
      </section>
    </>
  );
}

export default Layout;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
