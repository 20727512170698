import React from 'react';
import { Link } from 'gatsby';
import { isAuthenticated, login } from '../utils/auth';
import KeyIcon from '../assets/icons/key.svg';
import { secondaryNav, secondaryNavContent, link } from './secondary-nav.module.scss';
import AccountIcon from '../assets/icons/account.svg';
import ContactIcon from '../assets/icons/phone.svg';
import UsersIcon from '../assets/icons/users.svg';
const SecondaryNav: React.FC = () => (
  <div className={`${secondaryNav} is-hidden-mobile`}>
    <div className="container">
      <div className={secondaryNavContent}>
            <span className="icon-text">
              <span className="icon">
                <UsersIcon /> 
              </span>
              <Link to="/o-workflow/" className={link}>
                O nás
            </Link>
          </span>
      </div>
      <div className={secondaryNavContent}>
            <span className="icon-text">
              <span className="icon">
                <ContactIcon />
              </span>
              <Link to="/kontakt/" className={link}>
                Kontakt
            </Link>
          </span>
      </div>
      <div className={secondaryNavContent}>
          {isAuthenticated() ? (
            <span className="icon-text">
              <span className="icon">
                <AccountIcon />
              </span>
              <Link to="/muj-ucet/" className={link}>
                Můj účet
            </Link>
          </span>
        ) : (
          <a
              href="#login"
              className={link}
              onClick={(e) => {
                login();
                e.preventDefault();
              }}
            >
              <span className="icon-text">
                <span className="icon">
                  <KeyIcon />
                </span>{' '}
                <span>Přihlásit se</span>
              </span>
            </a>
          )}
      </div>
    </div>
  </div>
);

export default SecondaryNav; 